import React from "react";
import styles from "./SkillsGrid.module.css";
import skillsData from "./SkillsGridData";

const categories = ["Frontend", "Languages", "Backend", "Software"];

const SkillsGrid = () => {
  return (
    <div className="commonBackground">
    <div className={styles.SkillsGrid}>
      <h2 className={styles.heading}>Skills</h2>
      
      <div className={styles.categoryGrid}>
        {categories.map((category) => (
          <div key={category} className={styles.categorySection}>
            <h3 className={styles.categoryTitle}>{category}</h3>
            <div className={styles.skillsContainer}>
              {skillsData
                .filter((skill) => skill.category === category)
                .map((skill, index) => (
                  <div key={index} className={styles.skillTile}>
                    <img src={skill.src} alt={skill.alt} className={styles.skillImage} />
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default SkillsGrid;
