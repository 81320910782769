import React from "react";
import awardsData from "./Awards.data";

const AwardsSection = () => (
  <div className="commonBackground">
    <div>
      <div className="center">
        <h3>Awards</h3>
      </div>
      {awardsData.map((award, index) => (
        <div key={index} className="commonBackground">
          {typeof award === "string" ? (
            award
          ) : (
            <p>
              {award.text}
              <div className="column">
                {award.links.map((link, linkIndex) => (
                  <a
                    key={linkIndex}
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.icon ? <i className={link.icon}></i> : link.text}
                  </a>
                ))}
              </div>
            </p>
          )}
        </div>
      ))}
    </div>
  </div>
);

export default AwardsSection;
