import React from "react";
import styles from "./Home.css";
import SkillsGrid from "./components/SkillsGrid/SkillsGrid";
import AwardsSection from "./components/Awards/Awards";
import TestimonialsSection from "./components/Testimonials/Testimonials";

export default function Home() {
  return (
    <div className="container">
      <div className="lmn-h2 center">
        <h1>Mahanoor Syed</h1>
        <h6 class="center">
          Electronic and Information Engineering Graduate | Imperial College
          London | Founder of Tutortap Tuition{" "}
        </h6>
      </div>
      <SkillsGrid />
      <div className="row-container">
        <div className="awards-container">
          <AwardsSection />
        </div>
        <div className="testimonials-container">
          <TestimonialsSection />
        </div>
      </div>
    </div>
  );
}
