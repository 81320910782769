import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./Nav.css";

const Navbar = () => {
  const [show, setShow] = useState(false);
  const [cvText, setCvText] = useState("CV");
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleCvClick = () => {
    setCvText("Email for my CV");
    setIsPopupVisible(true);

    setTimeout(() => {
      setCvText("CV");
      setIsPopupVisible(false);
    }, 5000);
  };

  const handleNavClick = () => {
    setShow(false);
  };

  return (
    <div className="nav-container">
      <nav className="navbar navbar-expand-md">
        <div className="nav-left">
          <div className="hexagon">
            <img
              src={require("./images/mahanoor.jpg")}
              className="me"
              alt="Mahanoor"
              onClick={() => setShow(!show)}
            />
          </div>
        </div>
        <div className={`collapse navbar-collapse ${show ? "show" : ""}`} id="navbarCollapse">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink
                onClick={handleNavClick}
                className="nav-link"
                activeClassName="active"
                to="/"
                exact
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                onClick={handleNavClick}
                className="nav-link"
                activeClassName="active"
                to="/projects"
              >
                Projects
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                onClick={handleNavClick}
                className="nav-link"
                activeClassName="active"
                to="/resume"
              >
                Resume
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                onClick={handleNavClick}
                className="nav-link"
                activeClassName="active"
                to="https://www.linkedin.com/in/mahanoor-syed-8bb55b1ab"
                target="_blank"
              >
                <i className="fab fa-linkedin-in linkedin" />
              </NavLink>
            </li>
            <li className="nav-item">
              <div onClick={handleCvClick}>
                <button className="customButton nav-link2">{cvText} </button>
              </div>
            </li>
            <li className="nav-item">
              <NavLink
                onClick={handleNavClick}
                className="nav-link2"
                href="mailto:mahasyed03@hotmail.com"
                exact
              >
                <div className="customcenter">
                  <i className="fas fa-envelope" />
                </div>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                onClick={handleNavClick}
                className="nav-link"
                activeClassName="active"
                to="https://github.com/mahasch"
                target="_blank"
              >
                <div className="customcenter">
                  <i className="fab fa-github linkedin"></i>
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;