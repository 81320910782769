const skillsData = [
  // Languages
  { src: require("../../images/logos/python.png"), alt: "Python logo", category: "Languages" },
  { src: require("../../images/logos/c++.png"), alt: "C++ logo", category: "Languages" },
  { src: require("../../images/logos/c#.png"), alt: "C# logo", category: "Languages" },
  { src: require("../../images/logos/f#.png"), alt: "F# logo", category: "Languages" },
  { src: require("../../images/logos/julia.png"), alt: "Julia logo", category: "Languages" },
  { src: require("../../images/logos/solidity.png"), alt: "Solidity logo", category: "Languages" },

  // Frontend
  { src: require("../../images/logos/html.png"), alt: "HTML logo", category: "Frontend" },
  { src: require("../../images/logos/css.png"), alt: "CSS logo", category: "Frontend" },
  { src: require("../../images/logos/javascript.png"), alt: "JavaScript logo", category: "Frontend" },
  { src: require("../../images/logos/react.png"), alt: "React logo", category: "Frontend" },
  { src: require("../../images/logos/flutter.png"), alt: "Flutter logo", category: "Frontend" },
  { src: require("../../images/logos/angular.jpg"), alt: "Angular logo", category: "Frontend" },

  // Backend
  { src: require("../../images/logos/blazor.png"), alt: "Blazor logo", category: "Backend" },
  { src: require("../../images/logos/dynamoDB.png"), alt: "Dynamo logo", category: "Backend" },
  { src: require("../../images/logos/node.png"), alt: "Node logo", category: "Backend" },

  // Software
  { src: require("../../images/logos/github.png"), alt: "GitHub logo", category: "Software" },
  { src: require("../../images/logos/jupyterNotebook.png"), alt: "Jupyter Notebook logo", category: "Software" },
  { src: require("../../images/logos/rasberry.png"), alt: "Raspberry Pi logo", category: "Software" },
  { src: require("../../images/logos/ubuntu.png"), alt: "Ubuntu logo", category: "Software" },
  { src: require("../../images/logos/ltspice.jpg"), alt: "LTSpice logo", category: "Software" },
  { src: require("../../images/logos/tab.jpg"), alt: "Tableau logo", category: "Software" },
  { src: require("../../images/logos/figma.png"), alt: "Figma logo", category: "Software" },
  { src: require("../../images/logos/jira.png"), alt: "Jira logo", category: "Software" }
];

export default skillsData;
