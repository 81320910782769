import React from 'react';
import { Link } from 'react-router-dom';

export default function Volunteering() {
    return (
        <div class="container">

            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="navitem">
                    <Link class="nav-link" id="home-tab" to="/resume" role="tab" aria-controls="home" aria-selected="true">Experiences</Link>
                </li>
                <li class="navitem">
                    <Link class="nav-link" id="profile-tab" to="/education" role="tab" aria-controls="profile" aria-selected="false">Education</Link>
                </li>
                <li class="navitem">
                    <Link class="nav-link" id="profile-tab2" to="/volunteering" role="tab" aria-controls="profile" aria-selected="false">Volunteering</Link>
                </li>
            </ul>
            <div class="headering">
                <h1>Volunteering</h1>
            </div>
            <div class="gallery_f_inner imageGallery1">
                <div class=" brand  creative">
                    <div class="commonBackground">
                        <div class="center">
                            <div className="center">
                                <img src={require("./images/company/ie.jpg")} class="mr-3" alt="Imperial College London logo" />
                                <h4>Yearbook</h4>
                                <h6>Imperial College London</h6>
                                <p>Apr 2022 - July 2024</p>
                            </div>
                            <p>EE yearbook comitee <br />Helped create a yearbook for the entire yearbook with no funding and during exams and final year project term.</p>
                        </div>
                    </div>
                </div>

                <div class=" brand  creative">
                    <div class="commonBackground">
                        <div class="center">
                            <div className="center">
                                <img src={require("./images/company/imperial_logo.jpg")} class="mr-3" alt="Imperial College London logo" />
                                <h4>EIE Academic Rep</h4>
                                <h6>Imperial College London</h6>
                                <p>Oct 2022 - June 2024</p>
                            </div>
                            <p>Electrical Engineering <br />
                                Raised student concerns to the department and helped improve the student experience.
                            </p>
                        </div>
                    </div>
                </div>
                <div class=" brand  creative">
                    <div class="commonBackground">
                        <div class="center">
                            <div className="center">
                                <img src={require("./images/company/ie.jpg")} class="mr-3" alt="Imperial College London logo" />
                                <h4>Mums and Dads Scheme</h4>
                                <h6>Imperial College London</h6>
                                <p>Oct 2022 - June 2024</p>
                            </div>
                            <p>Imperial EESoc <br />Helped new 1st-year students settle into University and guided them with any questions and concerns they had.</p>
                        </div>
                    </div>
                </div>
                <div class=" brand creative">
                    <div class="commonBackground">
                        <div class="center">
                            <div className="center">
                                <img src={require("./images/company/paksoc.jpg")} class="mr-3" alt="Imperial College London logo" width="100" height="100" />
                                <h4>Mums and Dads Scheme</h4>
                                <h6>Imperial College London</h6>
                                <p>Oct 2021 - Jun 2022</p>
                            </div>
                            <p>Imperial Paksoc <br />Helped new 1st-year students settle into University and guided them with any questions and concerns they had.</p>
                        </div>
                    </div>
                </div>
                <div class=" brand  creative">
                    <div class="commonBackground">
                        <div class="center">
                            <div className="center">
                                <img src={require("./images/company/newham.jpg")} class="mr-3" alt="newham" width="110" height="110" />
                                <h5 class="mt-0">Keyboardist</h5>
                                <p>Jan 2016 - Dec 2017</p>
                            </div>
                            <p>Katherine Road Community Centre<br />I performed for the residents of a community during their community events and ensured that a positive atmosphere remained throughout the day.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}