const peerTestimonials = [
    {
      text: `"I have worked with Mahanoor many times and she is very quick to learn new programming languages, paradigms, and concepts. She has a determined work ethic and can get any job done."`,
      name: "Muhammad",
      title: "EIE graduate",
    },
    {
      text: `"I really enjoyed working with Mahanoor on multiple projects throughout the course. She is consistently the team member making sure everything is running smoothly and is very hardworking. Mahanoor is an outstanding engineer and I look forward to hopefully working with her next year as well."`,
      name: "Brendon Ferra",
      title: "EIE graduate",
    },
    {
      text: `"Mahanoor is a dependable, confident, and hardworking teammate. She consistently delivers results, earning the trust and respect of their team members. Their reliability, confidence in expressing opinions, and diligent work ethic make them an importance contributor to the team's success."`,
      name: "Charmaine Louie",
      title: "EIE graduate",
    },
  ];

export default peerTestimonials;
