const testimonialsData = [
    {
      image: require("../../images/people/tc.jpg"),
      text: `"Mahanoor has been one of the most memorable undergraduate students at the department of Electrical and Electronic Engineering. Throughout her time with us she has shown great energy and an enquiring mind. She will always speak up and ask the questions no-one else bothers. In her role as a student representative her valuable and clear contributions have helped us to understand student needs and reach wise decisions."`,
      name: "Dr Thomas Clarke",
      title:
        "Principal Teaching Fellow of Technological Transformation, Imperial College London",
    },
    {
      images: [
        require("../../images/people/mark.JPG"),
        require("../../images/people/james.JPG"),
        require("../../images/people/joshua.JPG"),
      ],
      text: `"Mahanoor is to be congratulated on successfully completing her project. She has achieved this by integrating a custom encased monitor unit, linked to a standard smartphone to present results and act as a comprehensive user interface. The initial project brief was unusually open-ended, and a couple of design iterations were needed to refine and finalise the eventual system to achieve an optimised solution. It has been a great pleasure to work with Mahanoor over the period of this project, and we all send her our very best wishes for the future."`,
      names: [
        {
          name: "Dr Mark Witkowski",
          title: "Honorary Research Fellow, Imperial College London",
        },
        {
          name: "Dr James Mardell",
          title:
            "Principal Engineer and Visiting Researcher, Imperial College London",
        },
        {
          name: "Dr Joshua Brown",
          title: "Research Associate in Haptics, Imperial College London",
        },
      ],
    },
  ];
  


export default testimonialsData;