import React from "react";
import { Link } from "react-router-dom";
import "./Resume.css";
import { useState } from "react";

export default function Resume() {
  return (
    <div class="container">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="navitem">
          <Link
            class="nav-link"
            id="home-tab"
            to="/resume"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            Experiences
          </Link>
        </li>
        <li class="navitem">
          <Link
            class="nav-link"
            id="profile-tab"
            to="/education"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
          >
            Education
          </Link>
        </li>
        <li class="navitem">
          <Link
            class="nav-link"
            id="profile-tab2"
            to="/volunteering"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
          >
            Volunteering
          </Link>
        </li>
      </ul>
      <h2></h2>
      <div className="headering">
        <h1>Experience</h1>
      </div>
      <div class="gallery_f_inner">
        
      <div class="brand creative">
          <div class="commonBackground">
            <div class="center">
              <div className="center">
                <img
                  src={require("./images/company/citi.jpg")}
                  class="mr-3"
                  alt="Citi logo"
                />
                <h5>Software Engineer</h5>
                <p>Citi Bank </p>
                <p>Current Job</p>
              </div>
              <ol>
                <li>Full Stack Dev working with Java and Angular </li>
                <li>
                  Representative for the 1st and 2nd year grad rotation Technology analyst cohort
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div class="brand creative">
          <div class="commonBackground">
            <div class="center">
              <div className="center">
                <img
                  src={require("./images/company/citi.jpg")}
                  class="mr-3"
                  alt="Citi logo"
                />
                <h5>Enterprise Operations and Technology Summer Analyst</h5>
                <p>Citi Bank </p>
                <p>June 2023 - August 2023</p>
              </div>
              <ol>
                <li>Neuda Net Migration Project (see projects) </li>
                <li>
                  {" "}
                  Wireframe Design for correcting balances new screen (see
                  projects){" "}
                </li>
              </ol>
            </div>
          </div>
        </div>

        
        <div class="brand  creative">
          <div class="commonBackground">
            <div class="center">
              <div className="center">
                <img
                  src={require("./images/company/imperial_logo.jpg")}
                  class="mr-3"
                  alt="Imperial College London logo"
                />
                <h5>Undergraduate Teaching Assistant</h5>
                <p> Imperial College London</p>
                <p>Oct 2022 - Present </p>
              </div>
              <p>
                Explain concepts and work through problem sheets with students
                in the 2nd year EIE course.
              </p>
            </div>
          </div>
        </div>
        {/* <div class="brand creative">
                    <div class="commonBackground">
                        <div class="center">
                            <div className="center">
                                <img src={require("./images/company/cvc.jpg")} class="mr-3" alt="CVC Capital Partners logo" />
                                <h5>Investment Analyst - Private Equity and Investment Relations</h5>
                                <p>CVC Capital Partners </p>
                                <p>Sep 2022</p>
                            </div>
                            <p>Collaborated in a group of 4 to develop a mock investment case with an investment amount of $10mn for Alphabet. Studied financial reports and forecasted profit over a 5yr period.</p>
                        </div>
                    </div>
                </div> */}
        {/* <div class="brand  creative">
                    <div class="commonBackground">
                        <div class="center">
                            <div className="center">
                                <img src={require("./images/company/bright_network.png")} class="mr-3" alt="Bright Network logo" />
                                <h5>Technology Consultant</h5>
                                <p>Bright Network</p>
                                <p>Jun 2021</p>
                            </div>
                            <p>Served as a technology consultant for EY by researching and presenting visualisation technologies.</p>
                        </div>
                    </div>
                </div> */}
        <div class="brand  creative">
          <div class="commonBackground">
            <div class="center">
              <div className="center">
                <img
                  src={require("./images/company/barclays_logo.jpg")}
                  class="mr-3"
                  alt="Barclays logo"
                />
                <h5>Bank Teller</h5>
                <p>Barclays </p>
                <p>Aug 2018 - Feb 2019 </p>
              </div>
              <p>
                Assisted customers with transactions and helped behind the desk
                by managing deposited and withdrawn money. Interacted in
                multiple languages, provided clarification about transactions
                and bills.
              </p>
            </div>
          </div>
        </div>
        {/* <div class="brand creative">
                    <div class="commonBackground">
                        <div class="center">
                            <div className="center">
                                <img src={require("./images/company/wdi.jpg")} class="mr-3" alt="Willmott Dixon Interiors logo" />
                                <h5>Construction and Quantitative Surveyor</h5>
                                <p>Willmott Dixon Interiors &middot; Indirect Contract</p>
                                <p>Aug 2018</p>
                            </div>
                            <p>Shadowed a quantity surveyor to ensure the project cost satisfied the company's budget. Assisted in the refurbishment of the Grove Park Youth Centre. I helped with the flooring, painting, and safety inspection.</p>
                        </div>
                    </div>
                </div> */}
      </div>
    </div>
  );
}
