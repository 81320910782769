const awardsData = [
  "Citi Global Tech Hackathon - 2nd place amongs London Technology Grads",
  "Huddlehive Hackathon - 1st place for most couragous project",
  "Microsoft HackTogether Hackathon - Honorable Mention for Nexus 2023",
  "Best Individual Research Project 2019",
  "Talent 2030 National Engineering Competition for Girls Finalist 2019",
  "Queen Mary University of London Mathematics Summer School Best Project Award 2019",
  {
    text: "1st Prize at the Houses of Parliament for the Chartered Institute of Building Challenge 2018",
    links: [
      {
        href: "https://www.ciob.org/blog/look-back-my-presidential-year",
        icon: "fa fa-link",
      },
      {
        href: "https://constructionmanagement.co.uk/ciob-and-mykindafuture-reveal-talent-and-potential/",
        icon: "fa fa-link",
      },
      {
        href: "https://issuu.com/constructionmanager6/docs/construction_manager_june_2018",
        text: "Page 46-47",
      },
    ],
  },
  "Jack Petchey Award 2014",
];

export default awardsData;
