import React from "react";
import testimonialsData from "./staffTestimonials.data";
import peerTestimonials from "./peerTestimonials.data";

const TestimonialsSection = () => (
  <div className="commonBackground">
    <div className="center">
      {testimonialsData.map((testimonial, index) => (
        <div key={index} className="commonBackground">
          <div className="image-column">
            {testimonial.image ? (
              <img
                src={testimonial.image}
                className="pics"
                alt={testimonial.name}
              />
            ) : (
              testimonial.images.map((imgSrc, imgIndex) => (
                <img
                  key={imgIndex}
                  src={imgSrc}
                  className="pics"
                  alt={`Contributor ${imgIndex + 1}`}
                />
              ))
            )}
          </div>
          <div className="text-column">
            <p>
              {testimonial.text}
              {testimonial.name && (
                <h6>
                  {testimonial.name}, {testimonial.title}
                </h6>
              )}
              {testimonial.names?.map((person, personIndex) => (
                <h6 key={personIndex}>
                  {person.name}, {person.title}
                </h6>
              ))}
            </p>
          </div>
        </div>
      ))}

      {peerTestimonials.map((peer, index) => (
        <div key={index} className="commonBackground">
          <div className="text-column">
            <p>
              {peer.text}
              <h6>
                {peer.name}, {peer.title}
              </h6>
            </p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default TestimonialsSection;
